<template>
  <div>
    <app-narrow class="show-for-phone" />
    <app-wide class="show-for-larger" />
  </div>
</template>

<script>
import narrow from '../components/basket/Narrow.vue';
import wide from '../components/basket/Wide.vue';

export default {
  name: 'Basket',
  components: {
    appNarrow: narrow,
    appWide: wide,
  },
  mounted() {
    this.$store.commit('garmentDeSelector');
  },
};
</script>
